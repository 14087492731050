const appTypeItems = [
    {
      name: 'Connected Search',
      tag: 'connected-search',
    },
    {
      name: 'Lucidworks Platform',
      tag: 'platform',
    }, 
    {
      name: 'Analytics Studio',
      tag: 'analytics'
    },
    {
      name: 'Lucidworks AI',
      tag: 'ai'
    },
    {
      name: 'Commerce Studio',
      tag: 'cs'
    }
]

const appTypePretty = {
  'connected-search': 'Connected Search',
  'platform': 'Lucidworks Platform',
  'analytics': 'Analytics Studio',
  'ai': 'Lucidworks AI',
  'cs': 'Commerce Studio'
}

// Use tag name to match subgroup item
const hideAppTypes = [
  // "analytics"
]


module.exports = {
    appTypeItems,
    appTypePretty,
    hideAppTypes
}